<template>
    <div class="index-page" :class="$route.params.disease">
        <router-view />
        <NavigationBar />
        <BottomNavigation />
        <transition name="fade" ease="in-out">
            <Disclaimer v-if="disclaimerPop" />
        </transition>
        <transition name="fade" ease="in-out">
            <AEDisclaimer v-if="aeDisclaimerPopUp" />
        </transition>
        <transition name="fade" ease="in-out">
            <RevealInformation v-if="revealInformationPopup" />
        </transition>
    </div>
</template>

<script>
import BottomNavigation from '@/components/BottomNavigation/BottomNavigationBar.vue';
import NavigationBar from '@/components/Navigation/NavigationBar.vue';
import AEDisclaimer from '@/components/Popup/AEDisclaimer.vue';
import Disclaimer from '@/components/Popup/Disclaimer.vue';
import RevealInformation from '@/components/Popup/RevealInformation.vue';
import Store from '@/store/index';
import liff from '@line/liff';
import { mapState } from 'vuex';

export default {
    components: {
        NavigationBar,
        BottomNavigation,
        Disclaimer,
        AEDisclaimer,
        RevealInformation
    },
    computed: {
        ...mapState({
            disclaimerPop: state => state.popup.disclaimerPop,
            aeDisclaimerPopUp: state => state.popup.aeDisclaimerPopUp,
            revealInformationPopup: state => state.popup.revealInformationPopup
        })
    },
    methods: {},
    beforeRouteEnter(to, from, next) {
        Store.commit('api/setDisease', to.params.disease);
        if (
            ['Line_NON_HCP', 'LineSplashScreen'].includes(to.name) ||
            Store.getters['auth/hasLogin']
        )
            next();
        else {
            liff.init({
                liffId: process.env.VUE_APP_LIFF_ID
            });
            next({
                name: 'LineSplashScreen',
                params: { name: to.name, disease: to.params.disease, params: to.params }
            });
        }
    }
};
</script>
